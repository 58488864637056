import {
	Settings as SettingsIcon,
	Cloud as CloudStorageIcon,
	ChevronLeft as ChevronLeftIcon,
	ArrowDropUp as ArrowDropUpIcon,
	Dashboard as DashboardIcon,
	VideogameAsset as VideoGameIcon,
	BugReport as BugIcon,
	OpenInBrowser as OpenApplicationIcon,
	Block as BlockIcon,
	Save as SaveIcon,
	PersonAdd as PersonAddIcon,
	Code as CodeIcon,
	VerifiedUser as LoginIcon,
	ExpandLess as ExpandLessIcon,
	HelpOutline as InfoIcon,
	FileCopy as CopyContent,
	MoreHoriz as MoreHorizIcon,
	GetApp as GetAppIcon,
	Publish as PublishIcon,
	GTranslate as GTranslateIcon,
	RawOn as RawOnIcon,
	Extension as ExtensionIcon,
} from "@mui/icons-material";

const icons = {
	Settings: SettingsIcon,
	GerverCluster: CloudStorageIcon,
	SolrCluster: CloudStorageIcon,
	CloudStorage: CloudStorageIcon,
	ChevronLeft: ChevronLeftIcon,
	ArrowDropUp: ArrowDropUpIcon,
	Dashboard: DashboardIcon,
	Playground: VideoGameIcon,
	Log: BugIcon,
	OpenApplication: OpenApplicationIcon,
	BlockIcon: BlockIcon,
	SaveIcon: SaveIcon,
	PersonAddIcon: PersonAddIcon,
	TEditorIcon: CodeIcon,
	Login: LoginIcon,
	ExpandLess: ExpandLessIcon,
	InfoIcon: InfoIcon,
	CopyContent: CopyContent,
	MoreHorizIcon: MoreHorizIcon,
	Download: GetAppIcon,
	Upload: PublishIcon,
	Localization: GTranslateIcon,
	RawOn: RawOnIcon,
	ExtensionIcon: ExtensionIcon,
} as const;

export default icons;
